<template>
  <div style="word-wrap: break-word !important;">
    <Loader />
    <div class="wrapper " style="word-wrap: break-word !important;">
      <HeaderStyle10 class="mb-5"   :visibleItems="true" />
      <!-- <SidebarStyle class=""/> -->
      <div class="bg-base text-break px-3 pt-4" style="word-wrap: break-word !important;">
        <transition name="router-anim" style="word-wrap: break-word !important;">
          <router-view />
        </transition>
      </div>
    </div>
    <!-- <FooterStyle /> -->
  </div>
</template>
<script>
// import SidebarStyle from "@/components/partials/backend/SidebarStyle/SidebarStyle";
import {core} from '../../config/pluginInit'
import Loader from "@/components/loader/Loader";
import HeaderStyle10 from "@/components/partials/backend/HeaderStyle/HeaderStyle10";
// import FooterStyle from "@/components/partials/backend/FooterStyle/FooterStyle";
export default {
  name: "Layout-10",
  components: {
    // SidebarStyle,
    HeaderStyle10,
    // FooterStyle,
    Loader,
  },
  mounted() {
    document.body.classList='color-light'
    document.body.classList = "home-payment";
    this.darkMode();
    core.fullscreen()
  },
  destroyed() {
    document.body.classList='color-light'
    document.body.classList = "home-payment";
    this.darkMode();
  },
  methods: {
    darkMode() {
      const isDarkModeActive = true;  //JSON.parse(localStorage.getItem("isdark")) == null ? true : JSON.parse(localStorage.getItem("isdark"));
      console.log("localstorage dark mounted layaout ", isDarkModeActive);
      const body = document.querySelector("body");
      if (isDarkModeActive) {
        body.classList.add("dark");
      } else {
        body.classList.remove("dark");
        this.$forceUpdate();
      }
    },
  },
};
</script>
<style></style>
