<template>
  <div id="loading" class="custom-loader">
    <h2 class="text-white">Espere ...</h2>
    <!-- <img src="../../assets/images/login/01.png" alt="logo_loader" /> -->
  </div>
</template>
<script>
import { animation } from "@/config/pluginInit";
export default {
  name: "Loader",
  watch: {
    $route: function () {
      this.init();
    },
  },
  computed: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const load = document.getElementById("loading");
      load.classList.remove("d-none");
      window.scrollTo({ top: 0, behavior: "smooth" });
      animation.fadeIn(load, { duration: 20 });
      setTimeout(() => {
        animation.fadeOut(load, { duration: 20 });
        load.classList.add("d-none");
      }, 1000);
    },
  },
};
</script>

<style scoped>
.custom-loader {
  display: grid;
  place-items: center;
  /* background: url(../../assets/images/fondo_nuevo_dashboard2.jpg); */
  backdrop-filter: blur(10px) !important;
  -webkit-backdrop-filter: blur(10px) !important;
  background-color: rgba(17, 25, 40, 0.25) !important;
}
.custom-loader img {
  width: 150px;
  animation: fadeAndZoom 1.2s infinite;
}

@keyframes fadeAndZoom {
  0%,
  100% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    transform: scale(1.5);
  }
  50% {
    opacity: 1;
    transform: translateY(-30px);
  }
}

/* @keyframes fadeAndZoom {
  0%,
  100% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.5);
  }
} */
</style>
